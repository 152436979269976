.plantingTab {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  flex: 1;
}

.itemSection {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  margin-bottom: 50px;
}

.sectionContents {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionTitles {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

.sectionTitles button {
  padding: 1px 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: medium;
  font-weight: 700;
}