.sectionDefaultBox {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  align-items: center;
  width: 120px;
  height: 150px;
  overflow: hidden;
  padding: 0px;
}

.Section-default-box p {
  padding: 5px 10px;
}

.Section-default-box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Section-default-box:hover {
  box-shadow: 2px 4px 16px rgba(0,0,0,.16);
}

.Section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}