.footer {
  width: 100%;
  display: flex;
  position: sticky;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.footerLinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255,0.7);
  backdrop-filter: blur(20px);
  box-shadow: 2px 4px 16px rgba(0,0,0,.16);
  }

.footer .inactive h4:hover {
  background-color: #f5f5f7;
  color: #1d1d1f;
  transform: scale3d(1.01,1.01,1.01);
  box-shadow: 2px 4px 16px rgba(0,0,0,.16);
}

.footer .active h4, .footer .inactive h4 {
  margin: 0;
  justify-content: center;
  flex-grow: 1;
  transition: all 0.3s ease;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
}

.footer .active h4 {
  color: #1d1d1f;
  background-color: #f5f5f7;
  box-shadow: 2px 4px 16px rgba(0,0,0,.16);
  transform: scale3d(1.01,1.01,1.01);
}

.footer .inactive h4 {
  background-color: #fff;
  color: #6e6e73;
}

@media only screen and (max-width: 600px) {
  .footerLinks {
    border-radius: 10px;
    padding: 5px;
  }
  .footer .active h4, .footer .inactive h4 {
    padding: 10px 10px;
    border-radius: 5px;
  } 
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  .footerLinks {
    border-radius: 15px;
    padding: 7.5px;
  }
  .footer .active h4, .footer .inactive h4 {
    padding: 10px 20px;
    border-radius: 7.5px;
  }
}

@media only screen and (min-width: 993px) {
  .footerLinks {
    border-radius: 20px;
    padding: 10px;
  }
  .footer .active h4, .footer .inactive h4 {
    padding: 10px 20px;
    border-radius: 10px;
  }
}
