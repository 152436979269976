:root {
  --font-family: 'Helvetica Neue', sans-serif;
  --letter-spacing: 0.5px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  letter-spacing: var(--letter-spacing);
  background-color: #f5f5f7;
}
#root {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  :root {
    --font-size: 14px;
    --line-height: 1;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  :root {
    --font-size: 16px;
    --line-height: 1.25;
  }
}

@media only screen and (min-width: 993px) {
  :root {
    --font-size: 20px;
    --line-height: 1.5;
  }
}