.homeTab {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
  flex: 1;
}

.defaultBox {
  height: fit-content;
  padding: 50px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
}
