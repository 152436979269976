.singleSectionMain {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    flex: 1;
}

.sectionSpecifics {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    margin-bottom: 50px;
    padding: 15px;
}

.sectionSpecifics button {
    padding: 1px 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: medium;
    font-weight: 700;
}

.singleSectionText {
    display: flex;
    flex-direction: column;
}

.singleSectionImage {
    display: flex;
    padding: 30px;
    justify-content: center;
}

.sectionFooter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px 30px 15px;
}

.plantingsList {
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    border-radius: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    background-color: #f5f5f7;
}

.plantingListHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
}

.plantingListContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.sectionDefaultBox {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  align-items: center;
  width: 120px;
  height: 150px;
  overflow: hidden;
  padding: 0px;
  margin: 15px;
}

.sectionDefaultBox p {
  padding: 5px 10px;
}

.sectionDefaultBox img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sectionDefaultBox:hover {
  box-shadow: 2px 4px 16px rgba(0,0,0,.16);
}
