.entireAddPlantingsPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  flex: 1;
}   

.addPlantings {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  margin-bottom: 50px;
}

.inputField {
  padding: 30px;
}

label {
  padding: 2em;
}

.footerElements {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
}

.footerElements button {
  padding: 1px 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: medium;
  font-weight: 700;
}